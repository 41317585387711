html,
body,
.root {
  margin: 0 auto;
  padding: 0 auto;
  font-family: "Roboto Condensed", sans-serif;
  width: 100%;
  height: 100%;
  overflow-x: hidden;  
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}
