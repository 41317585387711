.card-body button a {
    color: white;
  }
  
  .cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../plant3.jpg");
    height: 850px;
    max-width: 100%;
    background-size: cover;
    flex: 1;
  }
  
  .card {
    margin: 3% 30%;
    opacity: 0.8;
  }
  
  @media (max-width: 600px) {
    .card {
      margin: 3% 15%;
    }
  }
  

  