.button-container {
  text-align: left;
  padding-top: 2%;
}

@media (max-width: 600px) {
  button {
    font-size: 10px;
  }
}

input {
  font-family: inherit;
  color: #212529;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  width: 70px;
}

@media (max-width: 600px) {
  input {
    width: 40px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.tank-form {
  display: flex;
  flex-direction: column;
}

.tank-form-container {
  font-size: 14px;
  margin: 1% 10%;
}

@media (max-width: 1200px) {
  .tank-form-container {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .tank-form-container {
    font-size: 10px;
    margin: 1% 5%;
  }
}

@media (max-width: 600px) {
  .tank-form-container h5 {
    font-size: 1rem;
    margin: 5px 0;
  }
}

.tank-form-container label {
  line-height: 2.5em;
  padding: 0 5px;
}

.tank-form-container input {
  width: 100px;
}

.tank-form-container form {
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .tank-form-container form {
    padding-bottom: 15px;
  }
}

@media (max-width: 600px) {
  .tank-form div {
    display: flex;
    flex-direction: column;
  }
}

.tank-form-container h5 {
  border-top: .5px solid rgb(21, 112, 109);
  border-bottom: .5px solid rgb(21, 112, 109);
  text-align: left;
  margin: 10px 0;
  padding: 10px 0;
}
